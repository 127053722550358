<template>
  <v-container class="my-5">
    <h1>404 Error</h1>
    <h2>Sorry this Page could not be found.</h2>
    <h5>Get back to the <a href="/login">Login Page</a>.</h5>
  </v-container>
</template>

<script>
export default {
  name: "404",
};
</script>

<style>
</style>